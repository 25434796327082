import axiosClient from "@/axios";
import { DownloadFileResponse } from "@/models/DownloadFileResponse";
import { downloadFile } from "@/common/DownloadFile";

type DownloadFileExtension = "pdf" | "zip" | "csv" | "zpl" | null;

const downloadService = {
  download(
    url: string,
    fileName: string,
    extension: DownloadFileExtension
  ): Promise<unknown> {
    return axiosClient
      .get<DownloadFileResponse>(url, { responseType: "blob" })
      .then((response) => {
        if (extension === null) {
          const name = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0]
            .slice(1, -1);
          const extension = name.split(".")[1];
          downloadFile(response.data, `${fileName}.${extension}`);
          return;
        }
        downloadFile(response.data, `${fileName}.${extension}`);
      });
  },
  getLabel(id: string): void {
    this.download(`/parcels/${id}/label`, `parcel_label_${id}`, null);
  },
  getLabels(id: string): void {
    this.download(
      `/bulk-parcels/${id}/labels`,
      `bulk_parcels_labels_${id}`,
      "zip"
    );
  },
  getCsv(id: string): void {
    this.download(
      `/bulk-parcels/${id}/csv_parcels_data`,
      `bulk_parcels_csv_${id}`,
      "csv"
    );
  },
  getTemplate(): void {
    this.download(`/download_csv_template`, `csv_template`, "csv");
  },
};

export default downloadService;
